<template>
  <v-row no-gutters style="flex-wrap: nowrap">
    <v-col id="reportProducerSelect">
      <v-select
        hide-details
        :items="dropDownUserList"
        :prepend-icon="icons.mdiAccount"
        v-model="filterUser"
        :return-object="false"
        item-text="display"
        item-value="value"
        @change="changeUser"
        :disabled="loading.userList"
        :loading="loading.userList"
        class="mt-1"
      >
        <template v-slot:item="{ item }">
          <div class="dropDownItemText">
            {{ item.display }}
          </div>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
import { mdiAccount, mdiAlertCircleOutline } from '@mdi/js'
import { mapGetters } from 'vuex'

export default {
  props: [],
  data() {
    return {
      filterUser: '',
      hasUnmatched: false,
      icons: {
        mdiAccount,
        mdiAlertCircleOutline,
      },
      loading: {
        userList: false,
      },
    }
  },
  methods: {
    setUserFromRoute() {
      //      this.changeUser(this.$route.params.user)

      this.filterUser = this.$route.params.producer ?? ''
    },
    changeUser(producerId) {
      if (producerId) {
        this.$router.push({
          name: 'commission-overview.producer',
          params: {
            producer: producerId,
            month: this.$route.params.month,
            year: this.$route.params.year,
          },
        })
      } else {
        this.$router.push({
          name: 'commission-overview.summary',
          params: {
            month: this.$route.params.month,
            year: this.$route.params.year,
          },
        })
      }
    },
  },
  mounted() {
    this.setUserFromRoute()
  },
  watch: {
    '$route.path': function() {
      this.setUserFromRoute()
    },
  },
  computed: {
    ...mapGetters({
      producers: 'commissions/producers/getProducers',
    }),
    dropDownUserList() {
      let list = [
        {
          display: 'All Producers',
          value: '',
        },
      ]

      let producers = this.producers.map(producer => {
        return {
          display: producer.first_name + ' ' + producer.last_name,
          value: producer.id,
          approved: producer.approved,
        }
      })

      return [...list, ...producers]
    },
  },
}
</script>

<style lang="scss" scoped>
.user-status-icon {
  font-size: 9px;
  position: relative;
  left: -5px;
}

.dropdownItemText,
::v-deep .v-select__selection {
  color: #3a3541;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

::v-deep #reportProducerSelect {
  .v-input__prepend-outer {
    .v-icon {
      color: $ba-primary;
      margin-top: 12px;

      .v-icon__svg {
        height: 30px;
        width: 30px;
      }
    }
  }
}
</style>
