<template>
  <div>
    <div id="commissionOverviewTitleContainer">
      <h3 id="commissionsOverviewTitle">
        Commissions Overview
      </h3>
    </div>

    <div class="flex-grow-1" id="commissionOverviewFilterContainer">
      <div class="d-flex flex-wrap">
        <div class="flex-grow-1 mr-3 " style="max-width: 250px">
          <span class="filterSelectTitle">Report For:</span>
          <overview-producer-select />
        </div>
        <div class="flex-grow-1" style="max-width: 250px">
          <span class="filterSelectTitle">Pay Period:</span>

          <div>
            <v-col>
              <overview-date-picker />
            </v-col>
          </div>
        </div>

        <div class="flex-grow-1 text-right">
          <v-btn
            color="primary mt-5"
            @click.prevent="downloadCompleteSummary"
            v-if="$route.name === 'commission-overview.summary'"
          >
            DOWNLOAD REPORT
          </v-btn>
          <v-btn
            color="primary mt-5"
            @click.prevent="downloadProducerSummary"
            v-if="$route.name === 'commission-overview.producer'"
          >
            DOWNLOAD REPORT
          </v-btn>
        </div>
      </div>
    </div>

    <div id="summaryPanel">
      <router-view />
    </div>
  </div>
</template>

<script>
import OverviewProducerSelect from './components/OverviewProducerSelect.vue'
import OverviewDatePicker from './components/OverviewDatePicker.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      loading: {
        producers: true,
        download: false,
      },
    }
  },
  components: {
    OverviewProducerSelect,
    OverviewDatePicker,
  },
  methods: {
    ...mapActions({
      loadProducers: 'commissions/producers/loadProducers',
      loadCommissionSetup: 'commissions/loadCommissionSetup',
    }),
    downloadProducerSummary() {
      if (this.loading.download) {
        return
      }

      this.loading.download = true

      this.$api.commissionStatements
        .export({
          date: this.date,
          user: this.$route.params.producer,
        })
        .then(({ data }) => {
          window.location = data.file
          setTimeout(() => {
            this.loading.download = false
          }, 1500)
        })
        .catch(() => {
          this.loading.download = false
        })
    },
    downloadCompleteSummary() {
      if (this.loading.download) {
        return
      }

      this.loading.download = true

      this.$api.commissionMonthlyReview
        .exportDetailed({
          date: this.date,
        })
        .then(({ data }) => {
          window.location = data.file

          setTimeout(() => {
            this.loading.download = false
          }, 1500)
        })
        .catch(() => {
          this.loading.download = false
        })
    },
  },
  mounted() {
    this.loadCommissionSetup().then(() => {
      if (this.commissionSetup.completed === false) {
        this.$router.push({ name: 'commission-settings' })
        return
      }

      this.loadProducers({
        date: this.date,
      }).then(() => {
        this.loading.producers = false
      })
    })
  },
  computed: {
    ...mapGetters({
      commissionSetup: 'commissions/getCommissionSetup',
    }),
    date() {
      return this.$route.params.year + '-' + this.$route.params.month
    },
  },
}
</script>

<style lang="scss" scoped>
#commissionsOverviewTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: #3a3541;
}

#commissionOverviewTitleContainer {
  background: white;
  padding: 24px 32px 24px 32px;
  margin-bottom: 8px;
}

#commissionOverviewFilterContainer {
  background: #fff;
  padding: 24px 32px 16px 32px;
  margin-bottom: 8px;
}
.filterSelectTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3a3541;
  letter-spacing: 0.5px;
}

#summaryPanel {
  margin-bottom: 2px;
  background: #fff;
}
</style>
